import React from 'react'

const Footer = () => {
  return (
    <div className='flex items-center justify-center p-5 w-full bottom-0'>
      <span>&copy; 2022 SuperBootCamp - Azmi</span>
    </div>
  )
}

export default Footer